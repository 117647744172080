import React from 'react';
import { withRouter } from './withRouter.js';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        authState: this.props.authState,
        token: this.props.token
    }

  }

  async componentDidMount() {
    if(localStorage.getItem("token")) {
      var sessionToken = localStorage.getItem("token");
      this.setState({token: sessionToken});
    }

    var authState = await this.props.checkAuthState(sessionToken).then((result) => {
      this.setState({authState: true});
    }).catch((error) => {
      this.setState({authState: false});
      this.props.navigate('/login');
    });
  }

  render() {
    return(
      <div className="App">
      </div>
    );
  }
}

export default withRouter(Dashboard);