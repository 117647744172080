import React from 'react';
import axios from 'axios';
import { withRouter } from './withRouter.js';
import {baseURL} from '../config.js';

class EditIndividual extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        id: this.props.id,
        accountId: 0,
        authState: false,
        token: "",
        firstName: "",
        lastName: "",
        about: "",
        delete: 0
    };

    this.handleFirstName = this.handleFirstName.bind(this);
    this.handleLastName = this.handleLastName.bind(this);
    this.handleAbout = this.handleAbout.bind(this);

    this.editIndividual = this.editIndividual.bind(this);
    this.deleteIndividual = this.deleteIndividual.bind(this);
  }

  async componentDidMount() {
    if(localStorage.getItem("token")) {
      var sessionToken = localStorage.getItem("token");
      this.setState({token: sessionToken});
    }

    var authState = await this.props.checkAuthState(sessionToken).then((result) => {
      this.setState({authState: true});
      this.getData(sessionToken);
    }).catch((error) => {
      this.setState({authState: false});
      this.props.navigate('/login');
    });
  }

  getData(sessionToken) {
    axios.get(baseURL + '/individual/id/' + this.state.id, {
      headers: {
        'Authorization': 'Bearer ' + sessionToken
      }
    }, {}).then(response => {
      if(response.data) {
        var individual = response.data[0];

        this.setState({firstName: individual.first_name});
        this.setState({lastName: individual.last_name});
        this.setState({about: individual.about});
        this.setState({accountId: individual.account_id});

        this.getBusinessProfiles(sessionToken);
      } else {
        this.setState({error: "Error getting data"})
      }
    }).catch(error => {
      console.log(error);
    });
  }

  getBusinessProfiles(sessionToken) {
    console.log("trigger");
    axios.get(baseURL + '/business/account/' + this.state.accountId, {
      headers: {
        'Authorization': 'Bearer ' + sessionToken
      }
    }, {}).then(response => {
      if(response.data) {
        this.setState({delete: response.data.length});
      } else {
        this.setState({error: "Error getting data"})
      }
    }).catch(error => {
      console.log(error);
    });
  }

  handleFirstName(event) {
    this.setState({firstName: event.target.value});
  }

  handleLastName(event) {
    this.setState({lastName: event.target.value});
  }

  handleAbout(event) {
    this.setState({about: event.target.value});
  }

 editIndividual(event) {
    event.preventDefault();
    axios.put(baseURL + '/individual/' + this.state.id, { 
      id: this.state.id, 
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      about: this.state.about 
    },
    {
      headers: {
        'Authorization': 'Bearer ' + this.state.token
      }
    }).then(response => {
      if(response.data) {
        this.props.navigate('/individual');
      } else {
        this.setState({error: "Error getting data"})
      }
    }).catch(error => {
      console.log(error);
    });
  }

  deleteIndividual(id) {
    axios.delete(baseURL + '/individual/' + id, {
      headers: {
        'Authorization': 'Bearer ' + this.state.token
      }
    }, {}).then(response => {
      if(response.status == 200) {
        this.props.navigate('/individual');
      } else {
        this.setState({error: "Error getting data"})
      }
    }).catch(error => {
      console.log(error);
    });
  }

  render() {
    return(
        <div>
          <form onSubmit={this.editIndividual}>

            <input type="text" class="form-control" value={this.state.firstName} placeholder="Forename" onChange={this.handleFirstName} />
            <input type="text" class="form-control" value={this.state.lastName} placeholder="Surname" onChange={this.handleLastName} />

            <textarea class="form-control" style={{width: 100 + "%", height:10 + "rem"}} value={this.state.about} placeholder="About" onChange={this.handleAbout}></textarea>

            <input type="submit" class="btn btn-primary" value="Save" />
          </form>

          <hr/>

          <h3>Delete</h3>

          <p>User has {this.state.delete} business profiles</p>

          <button type="button" class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#exampleModal">Delete</button>

          <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">Confirm Delete</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                Are you sure you'd like to delete this profile?
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal" onClick={() => this.deleteIndividual(this.state.id)}>Confirm Delete</button>
              </div>
            </div>
          </div>
        </div>

        </div>
    );
  }
}

export default withRouter(EditIndividual);