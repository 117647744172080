import React from 'react';
import axios from 'axios';
import { withRouter } from './withRouter.js';
import {baseURL} from '../config.js';

class CreateUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      masterUser: false,
      password: "",
      validatePassword: "",
      token: ""
    };

    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handleMasterUser = this.handleMasterUser.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleValidatePasswordChange = this.handleValidatePasswordChange.bind(this);

    this.createUser = this.createUser.bind(this);
  }

  async componentDidMount() {
    if(localStorage.getItem("token")) {
      var sessionToken = localStorage.getItem("token");
      this.setState({token: sessionToken});
    }

    var authState = await this.props.checkAuthState(sessionToken).then((result) => {
      this.setState({authState: true});
    }).catch((error) => {
      this.setState({authState: false});
      this.props.navigate('/login');
    });
  }

  handleUsernameChange(event) {
    this.setState({username: event.target.value});
  }

  handleMasterUser(event) {
    console.log(document.getElementById("masteruser").checked);
    this.setState({masterUser: document.getElementById("masteruser").checked});
  }

  handlePasswordChange(event) {
    this.setState({password: event.target.value});
  }

  handleValidatePasswordChange(event) {
    this.setState({validatePassword: event.target.value});
  }

  createUser(event) {
    event.preventDefault();
    axios.post(baseURL + '/user', { 
      username: this.state.username,
      masterUser: this.state.masterUser,
      password: this.state.password,
      validatePassword: this.state.validatePassword
    }, {
      headers: {
        'Authorization': 'Bearer ' + this.state.token
      }
    }).then(response => {
      if(response.data) {
        this.props.navigate('/users');
      } else {
        this.setState({error: "Error getting data"})
      }
    }).catch(error => {
        this.setState({error: error.response.data})
        console.log(this.state.error);
    });
  }

  render() {
    return(
        <form onSubmit={this.createUser}>
            <span style={{color: "red"}} >{this.state.error}</span>

            <label class="form-label">Username:</label>
            <input type="text" class="form-control" value={this.state.username} onChange={this.handleUsernameChange} />

            <label class="form-label">Master User:</label>
            <input id="masteruser" type="checkbox" value="Master User" onChange={this.handleMasterUser}></input>

            <br />

            <label class="form-label">Password:</label>
            <input type="password" class="form-control" value={this.state.password} onChange={this.handlePasswordChange} />

            <label class="form-label">Confirm Password:</label>
            <input type="password" class="form-control" value={this.state.validatePassword} onChange={this.handleValidatePasswordChange} />

            <input type="submit" class="btn btn-primary" value="Create" />
        </form>
    );
  }
}

export default withRouter(CreateUser);