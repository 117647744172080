import React from 'react';
import axios from 'axios';
import { withRouter } from './withRouter.js';
import {baseURL} from '../config.js';

class CreateBusiness extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      emailAddress: "",
      password: "",
      validatePassword: "",
      token: "",
      error: "",
    };

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleEmailAddressChange = this.handleEmailAddressChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleValidatePasswordChange = this.handleValidatePasswordChange.bind(this);

    this.createBusiness = this.createBusiness.bind(this);
  }

  async componentDidMount() {
    if(localStorage.getItem("token")) {
      var sessionToken = localStorage.getItem("token");
      this.setState({token: sessionToken});
    }

    var authState = await this.props.checkAuthState(sessionToken).then((result) => {
      this.setState({authState: true});
    }).catch((error) => {
      this.setState({authState: false});
      this.props.navigate('/login');
    });
  }

  handleNameChange(event) {
    this.setState({name: event.target.value});
  }

  handleEmailAddressChange(event) {
    this.setState({emailAddress: event.target.value});
  }

  handlePasswordChange(event) {
    this.setState({password: event.target.value});
  }

  handleValidatePasswordChange(event) {
    this.setState({validatePassword: event.target.value});
  }

  createBusiness(event) {
    event.preventDefault();
    axios.post(baseURL + '/business', { 
      name: this.state.name,
      emailAddress: this.state.emailAddress,
      password: this.state.password,
      validatePassword: this.state.validatePassword
    }, {
      headers: {
        'Authorization': 'Bearer ' + this.state.token
      }
    }).then(response => {
      if(response.status == 200) {
        this.props.navigate('/business');
      } else {
        this.setState({error: response.data})
      }
    }).catch(error => {
        this.setState({error: error.response.data})
        console.log(this.state.error);
    });
  }

  render() {
    return(
        <div>
            <span style={{color: "red"}} >{this.state.error}</span>

            <form onSubmit={this.createBusiness}>

                <label class="form-label">Name:</label>
                <input type="text" class="form-control" value={this.state.name} onChange={this.handleNameChange} />

                <label class="form-label">Email Address:</label>
                <input type="text" class="form-control" value={this.state.emailAddress} onChange={this.handleEmailAddressChange} />

                <label class="form-label">Password:</label>
                <input type="password" class="form-control" value={this.state.password} onChange={this.handlePasswordChange} />

                <label class="form-label">Confirm Password:</label>
                <input type="password" class="form-control" value={this.state.validatePassword} onChange={this.handleValidatePasswordChange} />

                <input type="submit" class="btn btn-primary" value="Create" />
            </form>
        </div>
    );
  }
}

export default withRouter(CreateBusiness);