import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { withRouter } from './withRouter.js';

class Beliefs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authState: false,
      token: "",
      data: [],
      error: "",
      selectedId: 0
    }

    this.deleteBelief = this.deleteBelief.bind(this);
    this.trackId = this.trackId.bind(this);
  }

  async componentDidMount() {
    if(localStorage.getItem("token")) {
      var sessionToken = localStorage.getItem("token");
      this.setState({token: sessionToken});
    }

    var authState = await this.props.checkAuthState(sessionToken).then((result) => {
      this.setState({authState: true});
      this.getData(sessionToken);
    }).catch((error) => {
      this.setState({authState: false});
      this.props.navigate('/login');
    });
  }

  getData(token) {
    axios.get('http://cot-api.radical-test.co.uk:4000/beliefs', {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }, {}).then(response => {
      if(response.data) {
        this.setState({data: response.data})
      } else {
        this.setState({error: "Error getting data"})
      }
    }).catch(error => {
      console.log(error);
    });
  }

  deleteBelief(id) {
    axios.delete('http://cot-api.radical-test.co.uk:4000/beliefs/' + id, {
      headers: {
        'Authorization': 'Bearer ' + this.state.token
      }
    }, {}).then(response => {
      if(response.status == 200) {
        var beliefs = this.state.data.filter((belief) => belief.id !== id);
        this.setState({data: beliefs});
        console.log("deleted!");
        console.log(beliefs);
        console.log(this.state.data);
      } else {
        this.setState({error: "Error getting data"})
      }
    }).catch(error => {
      console.log(error);
    });
  }

  trackId(id) {
    this.setState({selectedId: id});
  }

  render() {
    return(
      <div className="App">
        <Link to={'/values/create'}>Create</Link>
        <br/>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.map((belief) => {
              return(
                <tr>
                  <td>{belief.id}</td>
                  <td>{belief.name}</td>
                  <td><Link to={`/values/edit/${belief.id}`}>Edit</Link></td>
                  <td><a href='#' onClick={() => this.trackId(belief.id)} data-bs-toggle="modal" data-bs-target="#exampleModal">Delete</a></td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">Confirm Delete</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                Are you sure you'd like to delete this value?
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal" onClick={() => this.deleteBelief(this.state.selectedId)}>Confirm Delete</button>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default withRouter(Beliefs);
