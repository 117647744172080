import React from 'react';
import axios from 'axios';
import { withRouter } from './withRouter.js';
import {baseURL} from '../config.js';

class ManageReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        id: this.props.id,
        authState: false,
        token: "",
        report: [],
        totalReports: [],
        post: []
    };

    this.dismissReport = this.dismissReport.bind(this);
    this.deleteReport = this.deleteReport.bind(this);
  }

  async componentDidMount() {
    if(localStorage.getItem("token")) {
      var sessionToken = localStorage.getItem("token");
      this.setState({token: sessionToken});
    }

    var authState = await this.props.checkAuthState(sessionToken).then((result) => {
      this.setState({authState: true});
      this.getReport(sessionToken);
    }).catch((error) => {
      this.setState({authState: false});
      this.props.navigate('/login');
    });
  }

  getReport(sessionToken) {
    axios.get(baseURL + '/report/' + this.state.id, {
      headers: {
        'Authorization': 'Bearer ' + sessionToken
      }
    }, {}).then(response => {
      if(response.data) {
        console.log(response.data.post_id);
        this.setState({report: response.data});
        this.getReports(sessionToken, response.data.post_id);
      } else {
        this.setState({error: "Error getting data"})
      }
    }).catch(error => {
      console.log(error);
    });
  }

  getReports(sessionToken, postId) {
    axios.get(baseURL + '/report/post/' + postId, {
      headers: {
        'Authorization': 'Bearer ' + sessionToken
      }
    }, {}).then(response => {
      if(response.data) {
        console.log(response.data);
        this.setState({totalReports: response.data});
        console.log(this.state.report.post_id);
        this.getPost(sessionToken, this.state.report.post_id);
      } else {
        this.setState({error: "Error getting data"})
      }
    }).catch(error => {
      console.log(error);
    });
  }

  getPost(sessionToken, postId) {
    axios.get(baseURL + '/post/' + postId, {
      headers: {
        'Authorization': 'Bearer ' + sessionToken
      }
    }, {}).then(response => {
      if(response.data) {
        console.log(response.data);
        this.setState({post: response.data[0]})
      } else {
        this.setState({error: "Error getting data"})
      }
    }).catch(error => {
      console.log(error);
    });
  }

 dismissReport() {
    axios.put(baseURL + '/report/' + this.state.id, { 
      status: 1
    },
    {
      headers: {
        'Authorization': 'Bearer ' + this.state.token
      }
    }).then(response => {
      if(response.status == 200) {
        this.props.navigate('/report');
      } else {
        this.setState({error: "Error getting data"})
      }
    }).catch(error => {
      console.log(error);
    });
  }

  deleteReport() {
    axios.delete(baseURL + '/report/' + this.state.id, {
      headers: {
        'Authorization': 'Bearer ' + this.state.token
      }
    }, {}).then(response => {
      if(response.status == 200) {
        this.props.navigate('/report');
      } else {
        this.setState({error: "Error getting data"})
      }
    }).catch(error => {
      console.log(error);
    });
  }

  render() {
    return(
        <div>
            <table>
                <tbody>
                    <tr>
                        <td>Post ID</td>
                        <td>{this.state.post.id}</td>
                    </tr>
                    <tr>
                        <td>Author ID</td>
                        <td>{this.state.post.author_id}</td>
                    </tr>
                    <tr>
                        <td>Content</td>
                        <td>{this.state.post.content}</td>
                    </tr>
                    <tr>
                        <td>Image</td>
                        <td>{this.state.post.image_url}</td>
                    </tr>
                </tbody>
            </table>

            <button type="button" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#dismissModal">Dismiss</button>
            <button type="button" class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#deleteModal">Delete</button>


            <div class="modal fade" id="dismissModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">Confirm Dismiss</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    Are you sure you'd like to dismiss this report?
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal" onClick={() => this.dismissReport()}>Confirm Dismiss</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">Confirm Delete</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    Are you sure you'd like to delete this post?
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal" onClick={() => this.deleteReport()}>Confirm Delete</button>
                  </div>
                </div>
              </div>
            </div>
        </div>
    );
  }
}

export default withRouter(ManageReport);