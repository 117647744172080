import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import axios from 'axios';
import Beliefs from './components/beliefs.js';
import CreateBelief from './components/createBelief.js';
import EditBelief from './components/editBelief';
import Sectors from './components/sectors.js';
import CreateSector from './components/createSector.js';
import EditSector from './components/editSector.js';
import Services from './components/services.js';
import CreateService from './components/createService.js';
import EditService from './components/editService.js';
import About from './components/about.js';
import Privacy from './components/privacy.js';
import Login from './components/login.js';
import Dashboard from './components/dashboard.js';
import FAQ from './components/faq.js';
import CreateFAQ from './components/createFAQ.js';
import EditFAQ from './components/editFAQ.js';
import Help from './components/help.js';
import CreateHelp from './components/createHelp.js';
import EditHelp from './components/editHelp.js';
import Report from './components/report.js';
import ManageReport from './components/manageReport.js';
import Users from './components/users.js';
import EditUser from './components/editUser.js';
import CreateUser from './components/createUser.js';
import Logout from './components/logout.js';
import Individual from './components/individual.js';
import CreateIndividual from './components/createIndividual.js';
import EditIndividual from './components/editIndividual.js';
import Business from './components/business.js';
import CreateBusiness from './components/createBusiness.js';
import EditBusiness from './components/editBusiness.js';

import {baseURL} from './config.js';
import UserAgreement from './components/userAgreement.js';

class App extends React.Component {

  constructor(props){
      super(props);

      this.state = {
        authState: false,
        token: ""
      }

      this.setAuthState = this.setAuthState.bind(this);
      this.checkAuthState = this.checkAuthState.bind(this);

  }

  async componentDidMount() {
    console.log("App container has loaded");
    if(localStorage.getItem("token")) {
      var sessionToken = localStorage.getItem("token");
      this.setState({token: sessionToken});
    }
    await this.checkAuthState(sessionToken).catch(function () {
      console.log("error");
    });
  }

  checkAuthState(token){
    return new Promise((resolve, reject) => {
      if(localStorage.getItem("token")) {
        axios.get(baseURL + '/test', {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }, {}).then(response => {
          if(response.data) {
            this.setAuthState(true);
            this.setState({token: localStorage.getItem("token")});
            resolve(true);
          } else {
            this.setAuthState(false);
            reject(false);
          }
        }).catch(error => {
          this.setAuthState(false);
          reject(false);
        });
      } else {
        reject(false);
      }
    });
  }

  setAuthState(authState){
    this.setState({authState: authState});
  }

  render(){
      return (
          <BrowserRouter>
              <Routes>
                  <Route path="/" element={<Dashboard checkAuthState={this.checkAuthState} />}></Route>
                  <Route path="/login" element={<Login setAuthState={this.setAuthState} />}></Route>

                  <Route path="/values" element={<Beliefs checkAuthState={this.checkAuthState} />}></Route>
                  <Route path="/values/create" element={<CreateBelief checkAuthState={this.checkAuthState} />}></Route>
                  <Route path="/values/edit/:id" element={<EditBelief checkAuthState={this.checkAuthState} />}></Route>

                  <Route path="/sectors" element={<Sectors authState={this.state.authState} checkAuthState={this.checkAuthState} token={this.state.token} />}></Route>
                  <Route path="/sectors/create" element={<CreateSector checkAuthState={this.checkAuthState} />}></Route>
                  <Route path="/sectors/edit/:id" element={<EditSector checkAuthState={this.checkAuthState} />}></Route>

                  <Route path="/services" element={<Services checkAuthState={this.checkAuthState} />}></Route>
                  <Route path="/services/create" element={<CreateService checkAuthState={this.checkAuthState} />}></Route>
                  <Route path="/services/edit/:id" element={<EditService checkAuthState={this.checkAuthState} />}></Route>

                  <Route path="/about" element={<About checkAuthState={this.checkAuthState} />}></Route>
                  <Route path="/privacy" element={<Privacy checkAuthState={this.checkAuthState} />}></Route>

                  <Route path="/faq" element={<FAQ checkAuthState={this.checkAuthState} />}></Route>
                  <Route path="/faq/create" element={<CreateFAQ checkAuthState={this.checkAuthState} />}></Route>
                  <Route path="/faq/edit/:id" element={<EditFAQ checkAuthState={this.checkAuthState} />}></Route>

                  <Route path="/help" element={<Help checkAuthState={this.checkAuthState} />}></Route>
                  <Route path="/help/create" element={<CreateHelp checkAuthState={this.checkAuthState} />}></Route>
                  <Route path="/help/edit/:id" element={<EditHelp checkAuthState={this.checkAuthState} />}></Route>

                  <Route path="/report" element={<Report checkAuthState={this.checkAuthState} />}></Route>
                  <Route path="/report/manage/:id" element={<ManageReport checkAuthState={this.checkAuthState} />}></Route>

                  <Route path="/userAgreement" element={<UserAgreement checkAuthState={this.checkAuthState} />}></Route>

                  <Route path="/users" element={<Users checkAuthState={this.checkAuthState} />}></Route>
                  <Route path="/users/create" element={<CreateUser checkAuthState={this.checkAuthState} />}></Route>
                  <Route path="/users/edit/:id" element={<EditUser checkAuthState={this.checkAuthState} />}></Route>
                  
                  <Route path="/logout" element={<Logout />}></Route>

                  <Route path="/individual" element={<Individual checkAuthState={this.checkAuthState} />}></Route>
                  <Route path="/individual/create" element={<CreateIndividual checkAuthState={this.checkAuthState} />}></Route>
                  <Route path="/individual/edit/:id" element={<EditIndividual checkAuthState={this.checkAuthState} />}></Route>

                  <Route path="/business" element={<Business checkAuthState={this.checkAuthState} />}></Route>
                  <Route path="/business/create" element={<CreateBusiness checkAuthState={this.checkAuthState} />}></Route>
                  <Route path="/business/edit/:id" element={<EditBusiness checkAuthState={this.checkAuthState} />}></Route>
              </Routes>
          </BrowserRouter>
      );
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
