import React from 'react';
import axios from 'axios';
import { withRouter } from './withRouter.js';
import {baseURL} from '../config.js';

class CreateSector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      token: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.createSector = this.createSector.bind(this);
  }

  async componentDidMount() {
    if(localStorage.getItem("token")) {
      var sessionToken = localStorage.getItem("token");
      this.setState({token: sessionToken});
    }

    var authState = await this.props.checkAuthState(sessionToken).then((result) => {
      this.setState({authState: true});
    }).catch((error) => {
      this.setState({authState: false});
      this.props.navigate('/login');
    });
  }

  handleChange(event) {
    this.setState({name: event.target.value});
  }

  createSector(event) {
    event.preventDefault();
    axios.post(baseURL + '/sectors', { 
      name: this.state.name 
    }, {
      headers: {
        'Authorization': 'Bearer ' + this.state.token
      }
    }).then(response => {
      if(response.data) {
        this.props.navigate('/sectors');
      } else {
        this.setState({error: "Error getting data"})
      }
    }).catch(error => {
      console.log(error);
    });
  }

  render() {
    return(
        <form onSubmit={this.createSector}>
        <label>
          Name:
          <input type="text" value={this.state.name} onChange={this.handleChange} />
        </label>
        <input type="submit" value="Create" />
      </form>
    );
  }
}

export default withRouter(CreateSector);