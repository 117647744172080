import React from 'react';
import axios from 'axios';
import { withRouter } from './withRouter.js';
import {baseURL} from '../config.js';

class EditBusiness extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        id: this.props.id,
        accountId: 0,
        authState: false,
        token: "",
        name: "",
        about: "",
        emailAddress: "",
        phoneNumber: "",
        website: "",
        booking: "",
        facebook: "",
        twitter: "",
        instagram: "",
        linkedin: "",
        delete: 0
    };

    this.handleName = this.handleName.bind(this);
    this.handleAbout = this.handleAbout.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.handleWebsite = this.handleWebsite.bind(this);
    this.handleBooking = this.handleBooking.bind(this);
    this.handleFacebook = this.handleFacebook.bind(this);
    this.handleTwitter = this.handleTwitter.bind(this);
    this.handleInstagram = this.handleInstagram.bind(this);
    this.handleLinkedin = this.handleLinkedin.bind(this);

    this.editBusiness = this.editBusiness.bind(this);
    this.deleteBusiness = this.deleteBusiness.bind(this);
  }

  async componentDidMount() {
    if(localStorage.getItem("token")) {
      var sessionToken = localStorage.getItem("token");
      this.setState({token: sessionToken});
    }

    var authState = await this.props.checkAuthState(sessionToken).then((result) => {
      this.setState({authState: true});
      this.getData(sessionToken);
    }).catch((error) => {
      this.setState({authState: false});
      this.props.navigate('/login');
    });
  }

  getData(sessionToken) {
    axios.get(baseURL + '/business/id/' + this.state.id, {
      headers: {
        'Authorization': 'Bearer ' + sessionToken
      }
    }, {}).then(response => {
      if(response.data) {
        var business = response.data[0];

        this.setState({name: business.name});
        this.setState({about: business.about});
        this.setState({emailAddress: business.email_address});
        this.setState({phoneNumber: business.phone_number});
        this.setState({website: business.website_url});
        this.setState({booking: business.booking_url});
        this.setState({facebook: business.facebook_handle});
        this.setState({twitter: business.twitter_handle});
        this.setState({instagram: business.instagram_handle});
        this.setState({linkedin: business.linkedin_handle});

        this.setState({accountId: business.account_id});

        this.getBusinessProfiles(sessionToken);
      } else {
        this.setState({error: "Error getting data"})
      }
    }).catch(error => {
      console.log(error);
    });
  }

  getBusinessProfiles(sessionToken) {
    console.log("trigger");
    axios.get(baseURL + '/business/account/' + this.state.accountId, {
      headers: {
        'Authorization': 'Bearer ' + sessionToken
      }
    }, {}).then(response => {
      if(response.data) {
        this.setState({delete: response.data.length});
      } else {
        this.setState({error: "Error getting data"})
      }
    }).catch(error => {
      console.log(error);
    });
  }

  handleName(event) {
    this.setState({name: event.target.value});
  }

  handleAbout(event) {
    this.setState({about: event.target.value});
  }

  handleEmail(event) {
    this.setState({emailAddress: event.target.value});
  }

  handlePhone(event) {
    this.setState({phoneNumber: event.target.value});
  }

  handleWebsite(event) {
    this.setState({website: event.target.value});
  }

  handleBooking(event) {
    this.setState({booking: event.target.value});
  }

  handleFacebook(event) {
    this.setState({facebook: event.target.value});
  }

  handleTwitter(event) {
    this.setState({twitter: event.target.value});
  }

  handleInstagram(event) {
    this.setState({instagram: event.target.value});
  }

  handleLinkedin(event) {
    this.setState({linkedin: event.target.value});
  }

 editBusiness(event) {
    event.preventDefault();
    axios.put(baseURL + '/business/' + this.state.id, { 
      id: this.state.id, 
      name: this.state.name,
      about: this.state.about,
      emailAddress: this.state.emailAddress,
      phoneNumber: this.state.phoneNumber,
      website: this.state.website,
      booking: this.state.booking,
      facebook: this.state.facebook,
      twitter: this.state.twitter,
      instagram: this.state.instagram,
      linkedin: this.state.linkedin
    },
    {
      headers: {
        'Authorization': 'Bearer ' + this.state.token
      }
    }).then(response => {
      if(response.data) {
        this.props.navigate('/business');
      } else {
        this.setState({error: "Error getting data"})
      }
    }).catch(error => {
      console.log(error);
    });
  }

  deleteBusiness(id) {
    axios.delete(baseURL + '/business/' + id, {
      headers: {
        'Authorization': 'Bearer ' + this.state.token
      }
    }, {}).then(response => {
      if(response.status == 200) {
        this.props.navigate('/business');
      } else {
        this.setState({error: "Error getting data"})
      }
    }).catch(error => {
      console.log(error);
    });
  }

  render() {
    return(
        <div>
          <form onSubmit={this.editBusiness}>

            <label>Name:</label>
            <input type="text" class="form-control" value={this.state.name} placeholder="Name" onChange={this.handleName} />

            <label>About:</label>
            <textarea class="form-control" style={{width: 100 + "%", height:10 + "rem"}} value={this.state.about} placeholder="About" onChange={this.handleAbout}></textarea>

            <label>Email:</label>
            <input type="text" class="form-control" value={this.state.emailAddress} placeholder="info@example.com" onChange={this.handleEmail} />

            <label>Phone:</label>
            <input type="text" class="form-control" value={this.state.phoneNumber} placeholder="012345 678901" onChange={this.handlePhone} />

            <label>Website:</label>
            <input type="text" class="form-control" value={this.state.website} placeholder="example.com" onChange={this.handleWebsite} />

            <label>Booking:</label>
            <input type="text" class="form-control" value={this.state.booking} placeholder="example.com/booking" onChange={this.handleBooking} />

            <label>Facebook:</label>
            <input type="text" class="form-control" value={this.state.facebook} onChange={this.handleFacebook} />

            <label>Twitter:</label>
            <input type="text" class="form-control" value={this.state.twitter} onChange={this.handleTwitter} />

            <label>Instagram:</label>
            <input type="text" class="form-control" value={this.state.instagram} onChange={this.handleInstagram} />

            <label>LinkedIn:</label>
            <input type="text" class="form-control" value={this.state.linkedin} onChange={this.handleLinkedin} />

            <input type="submit" class="btn btn-primary" value="Save" />
          </form>

          <hr/>

          <h3>Delete</h3>

          <p>User has {this.state.delete} business profiles</p>

          <button type="button" class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#exampleModal">Delete</button>

          <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">Confirm Delete</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                Are you sure you'd like to delete this profile?
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal" onClick={() => this.deleteBusiness(this.state.id)}>Confirm Delete</button>
              </div>
            </div>
          </div>
        </div>

        </div>
    );
  }
}

export default withRouter(EditBusiness);