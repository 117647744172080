import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {baseURL} from '../config.js';
import { withRouter } from './withRouter.js';

class Services extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        authState: false,
        token: "",
        data: [],
        sectors: [],
        error: "",
        selectedId: 0
    }

    this.deleteService = this.deleteService.bind(this);
    this.trackId = this.trackId.bind(this);
  }

  async componentDidMount() {
    if(localStorage.getItem("token")) {
      var sessionToken = localStorage.getItem("token");
      this.setState({token: sessionToken});
    }

    var authState = await this.props.checkAuthState(sessionToken).then((result) => {
      this.setState({authState: true});
      this.getSectors(sessionToken);
      this.getData(sessionToken);
    }).catch((error) => {
      this.setState({authState: false});
      this.props.navigate('/login');
    });
  }

  getData(token) {
    axios.get(baseURL + '/services', {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }, {}).then(response => {
      if(response.data) {
        this.setState({data: response.data})
      } else {
        this.setState({error: "Error getting data"})
      }
    }).catch(error => {
      console.log(error);
    });
  }

  getSectors(token) {
    axios.get(baseURL + '/sectors', {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }, {}).then(response => {
      if(response.data) {
        this.setState({sectors: response.data})
      } else {
        this.setState({error: "Error getting data"})
      }
    }).catch(error => {
      console.log(error);
    });
  }

  deleteService(id) {
    axios.delete(baseURL + '/services/' + id, {
      headers: {
        'Authorization': 'Bearer ' + this.state.token
      }
    }, {}).then(response => {
      if(response.status == 200) {
        var services = this.state.data.filter((service) => service.id !== id);
        this.setState({data: services});
        console.log("deleted!");
        console.log(services);
        console.log(this.state.data);
      } else {
        this.setState({error: "Error getting data"})
      }
    }).catch(error => {
      console.log(error);
    });
  }

  trackId(id) {
    this.setState({selectedId: id});
  }

  render() {
    return(
      <div className="App">
        <Link to={'/services/create'}>Create</Link>
        <br/>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Sector</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.map((service) => {
                const sector = this.state.sectors.filter(sector => sector.id === service.sector_id);
                const sectorName = sector[0].name;
                return(
                    <tr>
                    <td>{service.id}</td>
                    <td>{service.name}</td>
                    <td>{sectorName}</td>
                    <td><Link to={`/services/edit/${service.id}`}>Edit</Link></td>
                    <td><a href='#' onClick={() => this.trackId(service.id)} data-bs-toggle="modal" data-bs-target="#exampleModal">Delete</a></td>
                    </tr>
                );
            })}
          </tbody>
        </table>

        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">Confirm Delete</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                Are you sure you'd like to delete this service?
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal" onClick={() => this.deleteService(this.state.selectedId)}>Confirm Delete</button>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default withRouter(Services);