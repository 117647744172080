import React from 'react';
import axios from 'axios';
import { withRouter } from './withRouter.js';
import {baseURL} from '../config.js';

class EditUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        id: this.props.id,
        accountId: 0,
        authState: false,
        token: "",
        username: "",
        password: "",
        masterUser: "",
        delete: 0
    };

    this.handlePassword = this.handlePassword.bind(this);
    this.handleMasterUser = this.handleMasterUser.bind(this);

    this.changePassword = this.changePassword.bind(this);
    this.resetSession = this.resetSession.bind(this);
    this.setMasterUser = this.setMasterUser.bind(this);
  }

  async componentDidMount() {
    if(localStorage.getItem("token")) {
      var sessionToken = localStorage.getItem("token");
      this.setState({token: sessionToken});
    }

    var authState = await this.props.checkAuthState(sessionToken).then((result) => {
      this.setState({authState: true});
      this.getData(sessionToken);
    }).catch((error) => {
      this.setState({authState: false});
      this.props.navigate('/login');
    });
  }

  getData(sessionToken) {
    axios.get(baseURL + '/user/id/' + this.state.id, {
      headers: {
        'Authorization': 'Bearer ' + sessionToken
      }
    }, {}).then(response => {
      if(response.data) {
        var user = response.data[0];

        this.setState({username: user.username});
        this.setState({masterUser: user.master_user});
        this.setState({accountId: user.id});

        if(user.master_user == true) {
          document.getElementById("masteruser").checked = true;
        }
      } else {
        this.setState({error: "Error getting data"})
      }
    }).catch(error => {
      console.log(error);
    });
  }

  handlePassword(event) {
    this.setState({password: event.target.value});
  }

  handleMasterUser(event) {
    console.log(document.getElementById("masteruser").checked);
    this.setState({masterUser: document.getElementById("masteruser").checked});
  }

  changePassword() {
    axios.put(baseURL + '/user/password', { 
      id: this.state.id, 
      password: this.state.password,
    },
    {
      headers: {
        'Authorization': 'Bearer ' + this.state.token
      }
    }).then(response => {
      if(response.data) {
        this.props.navigate('/users');
      } else {
        this.setState({error: "Error getting data"})
      }
    }).catch(error => {
      console.log(error);
    });
  }

  setMasterUser() {
    axios.put(baseURL + '/user/master', { 
      id: this.state.id, 
      masterUser: this.state.masterUser,
    },
    {
      headers: {
        'Authorization': 'Bearer ' + this.state.token
      }
    }).then(response => {
      if(response.data) {
        this.props.navigate('/users');
      } else {
        this.setState({error: "Error getting data"})
      }
    }).catch(error => {
      console.log(error);
    });
  }

  resetSession() {
    axios.post(baseURL + '/user/session', { 
      id: this.state.id,
    },
    {
      headers: {
        'Authorization': 'Bearer ' + this.state.token
      }
    }).then(response => {
      if(response.data) {
        this.props.navigate('/users');
      } else {
        this.setState({error: "Error getting data"})
      }
    }).catch(error => {
      console.log(error);
    });
  }

  render() {
    return(
        <div>

            <h2>Password</h2>

            <input type="password" class="form-control" onChange={this.handlePassword} />

            <button type="button" class="btn btn-primary" onClick={this.changePassword}>Change Password</button>

            <hr />

            <h2>Master User</h2>

            <input id="masteruser" type="checkbox" value="Master User" onChange={this.handleMasterUser}></input>

            <button type="button" class="btn btn-primary" onClick={this.setMasterUser}>Set Master User</button>

            <hr />

            <h2>Reset Session</h2>

            <button type="button" class="btn btn-primary" onClick={this.resetSession}>Reset Session</button>

        </div>
    );
  }
}

export default withRouter(EditUser);