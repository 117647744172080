import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {baseURL} from '../config.js';
import { withRouter } from './withRouter.js';

class Individual extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        data: [],
        authState: this.props.authState,
        token: "",
        error: "",
        selectedId: 0
    }

    this.handleSearch = this.handleSearch.bind(this);
  }

  async componentDidMount() {
    if(localStorage.getItem("token")) {
      var sessionToken = localStorage.getItem("token");
      this.setState({token: sessionToken});
    }

    console.log(sessionToken);

    var authState = await this.props.checkAuthState(sessionToken).then((result) => {
        console.log("success");
      this.setState({authState: true});
    }).catch((error) => {
        console.log("failure");
      this.setState({authState: false});
      this.props.navigate('/login');
    });
  }

  getData(token, searchString) {
    axios.get(baseURL + '/individual/' + searchString, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }, {}).then(response => {
      if(response.data) {
        this.setState({data: response.data})
      } else {
        this.setState({error: "Error getting data"})
      }
    }).catch(error => {
      console.log(error);
    });
  }

  handleSearch(event) {
    this.getData(this.state.token, event.target.value);
  }

  render() {
    return(
      <div className="App">
        <Link to={'/individual/create'}>Create</Link>
        <br/>

        <input type="text" class="form-control" placeholder="Search for individuals..." onChange={this.handleSearch} />

        <br/>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Forename</th>
              <th>Surname</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.map((individual) => {
              return(
                <tr>
                  <td>{individual.id}</td>
                  <td>{individual.first_name}</td>
                  <td>{individual.last_name}</td>
                  <td><Link to={`/individual/edit/${individual.id}`}>Edit</Link></td>
                </tr>
              );
            })}
          </tbody>
        </table>

      </div>
    );
  }
}

export default withRouter(Individual);