import React from 'react';
import axios from 'axios';
import { withRouter } from './withRouter.js';
import {baseURL} from '../config.js';

class CreateIndividual extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      emailAddress: "",
      password: "",
      validatePassword: "",
      token: ""
    };

    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handleEmailAddressChange = this.handleEmailAddressChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleValidatePasswordChange = this.handleValidatePasswordChange.bind(this);

    this.createIndividual = this.createIndividual.bind(this);
  }

  async componentDidMount() {
    if(localStorage.getItem("token")) {
      var sessionToken = localStorage.getItem("token");
      this.setState({token: sessionToken});
    }

    var authState = await this.props.checkAuthState(sessionToken).then((result) => {
      this.setState({authState: true});
    }).catch((error) => {
      this.setState({authState: false});
      this.props.navigate('/login');
    });
  }

  handleFirstNameChange(event) {
    this.setState({firstName: event.target.value});
  }

  handleLastNameChange(event) {
    this.setState({lastName: event.target.value});
  }

  handleEmailAddressChange(event) {
    this.setState({emailAddress: event.target.value});
  }

  handlePasswordChange(event) {
    this.setState({password: event.target.value});
  }

  handleValidatePasswordChange(event) {
    this.setState({validatePassword: event.target.value});
  }

  createIndividual(event) {
    event.preventDefault();
    axios.post(baseURL + '/individual', { 
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      emailAddress: this.state.emailAddress,
      password: this.state.password,
      validatePassword: this.state.validatePassword
    }, {
      headers: {
        'Authorization': 'Bearer ' + this.state.token
      }
    }).then(response => {
      if(response.data) {
        this.props.navigate('/individual');
      } else {
        this.setState({error: "Error getting data"})
      }
    }).catch(error => {
        this.setState({error: error.response.data})
        console.log(this.state.error);
    });
  }

  render() {
    return(
        <form onSubmit={this.createIndividual}>
            <span style={{color: "red"}} >{this.state.error}</span>

            <label class="form-label">First Name:</label>
            <input type="text" class="form-control" value={this.state.firstName} onChange={this.handleFirstNameChange} />

            <label class="form-label">Last Name:</label>
            <input type="text" class="form-control" value={this.state.lastName} onChange={this.handleLastNameChange} />

            <label class="form-label">Email Address:</label>
            <input type="text" class="form-control" value={this.state.emailAddress} onChange={this.handleEmailAddressChange} />

            <label class="form-label">Password:</label>
            <input type="password" class="form-control" value={this.state.password} onChange={this.handlePasswordChange} />

            <label class="form-label">Confirm Password:</label>
            <input type="password" class="form-control" value={this.state.validatePassword} onChange={this.handleValidatePasswordChange} />

            <input type="submit" class="btn btn-primary" value="Create" />
        </form>
    );
  }
}

export default withRouter(CreateIndividual);