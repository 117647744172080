import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {baseURL} from '../config.js';
import { withRouter } from './withRouter.js';

class Privacy extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        authState: false,
        content: "",
        token: "",
        error: ""
    }

    this.handleChange = this.handleChange.bind(this);
    this.updateAbout = this.updateAbout.bind(this);
  }

  async componentDidMount() {
    if(localStorage.getItem("token")) {
      var sessionToken = localStorage.getItem("token");
      this.setState({token: sessionToken});
    }

    var authState = await this.props.checkAuthState(sessionToken).then((result) => {
      this.setState({authState: true});
      this.getData(sessionToken);
    }).catch((error) => {
      this.setState({authState: false});
      this.props.navigate('/login');
    });
  }

  handleChange(event) {
    this.setState({content: event.target.value});
  }

  getData(token) {
    axios.get(baseURL + '/pages/privacy_policy', {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }, {}).then(response => {
      if(response.data) {
        this.setState({content: response.data[0].content})
      } else {
        this.setState({error: "Error getting data"})
      }
    }).catch(error => {
      console.log(error);
    });
  }

  updateAbout(id) {
    axios.put(baseURL + '/pages/privacy_policy', { 
      content: this.state.content 
    }, {
      headers: {
        'Authorization': 'Bearer ' + this.state.token
      }
    }).then(response => {
      if(response.status == 200) {
        
      } else {
        this.setState({error: "Error getting data"})
      }
    }).catch(error => {
      console.log(error);
    });
  }

  render() {
    return(
      <div className="App">
        <form onSubmit={this.updateAbout}>
        <textarea style={{width: 100 + "%", height:10 + "rem"}} value={this.state.content} onChange={this.handleChange}></textarea>
        <input type="submit" value="Save" />
        </form>
      </div>
    );
  }
}

export default withRouter(Privacy);