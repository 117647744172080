import React from 'react';
import { withRouter } from './withRouter.js';
import axios from 'axios';
import {baseURL} from '../config.js';

class Login extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            isError: "",
            username: "",
            password: ""
        }

        this.submit = this.submit.bind(this);
        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
    }

    handleUsernameChange(event) {
        this.setState({username: event.target.value});
    }

    handlePasswordChange(event) {
        this.setState({password: event.target.value});
    }

    submit(event){
        event.preventDefault();
        axios.post(baseURL + '/login', {
            username: this.state.username,
            password: this.state.password
        }).then(response => {
            console.log(response);
            if(response.data) {
                localStorage.setItem('token', response.data.token)
                this.props.setAuthState(true);
                this.props.navigate('/')
            } else {
                console.log("Error: Login error")
            }
        }).catch(error => {
            console.log(error);
            this.props.setAuthState(false);
        });
    }

    render(){
        return(
            <form onSubmit={this.submit}>
                <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">Username</label>
                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" value={this.state.username} onChange={this.handleUsernameChange} />
                </div>
                <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label">Password</label>
                    <input type="password" class="form-control" id="exampleInputPassword1" value={this.state.password} onChange={this.handlePasswordChange} />
                </div>
                <button type="submit" class="btn btn-primary">Login</button>
            </form>
        );
    }
}

export default withRouter(Login)