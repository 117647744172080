import React from 'react';
import axios from 'axios';
import { withRouter } from './withRouter.js';
import {baseURL} from '../config.js';

class CreateHelp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      token: "",
      topic: "",
      content: "",
      order: 0
    };

    this.handleChangeQuestion = this.handleChangeQuestion.bind(this);
    this.handleChangeAnswer = this.handleChangeAnswer.bind(this);
    this.handleChangeOrder = this.handleChangeOrder.bind(this);

    this.createHelp = this.createHelp.bind(this);
  }

  async componentDidMount() {
    if(localStorage.getItem("token")) {
      var sessionToken = localStorage.getItem("token");
      this.setState({token: sessionToken});
    }

    var authState = await this.props.checkAuthState(sessionToken).then((result) => {
      this.setState({authState: true});
    }).catch((error) => {
      this.setState({authState: false});
      this.props.navigate('/login');
    });
  }

  handleChangeQuestion(event) {
    this.setState({topic: event.target.value});
  }

  handleChangeAnswer(event) {
    this.setState({content: event.target.value});
  }

  handleChangeOrder(event) {
    this.setState({order: parseInt(event.target.value)});
  }

  createHelp(event) {
    event.preventDefault();
    axios.post(baseURL + '/help', { 
      topic: this.state.topic,
      content: this.state.content,
      order: this.state.order 
    }, {
      headers: {
        'Authorization': 'Bearer ' + this.state.token
      }
    }).then(response => {
      if(response.data) {
        this.props.navigate('/help');
      } else {
        this.setState({error: "Error getting data"})
      }
    }).catch(error => {
      console.log(error);
    });
  }

  render() {
    return(
        <form onSubmit={this.createHelp}>
        <label>
          Topic:
          <input type="text" value={this.state.topic} onChange={this.handleChangeQuestion} />
        </label>
        <textarea style={{width: 100 + "%", height:10 + "rem"}} value={this.state.content} onChange={this.handleChangeAnswer}></textarea>
        <label>
          Order:
          <input type="number" value={this.state.order} onChange={this.handleChangeOrder} min="1" max="100" />
        </label>
        <input type="submit" value="Create" />
      </form>
    );
  }
}

export default withRouter(CreateHelp);