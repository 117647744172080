import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {baseURL} from '../config.js';
import { withRouter } from './withRouter.js';

class Report extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        data: [],
        authState: this.props.authState,
        token: this.props.token,
        error: ""
    }

  }

  async componentDidMount() {
    if(localStorage.getItem("token")) {
      var sessionToken = localStorage.getItem("token");
      this.setState({token: sessionToken});
    }

    var authState = await this.props.checkAuthState(sessionToken).then((result) => {
      this.setState({authState: true});
      this.getData(sessionToken);
    }).catch((error) => {
      this.setState({authState: false});
      this.props.navigate('/login');
    });
  }

  getData(token) {
    axios.get(baseURL + '/report', {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }, {}).then(response => {
      if(response.data) {
        this.setState({data: response.data})
      } else {
        this.setState({error: "Error getting data"})
      }
    }).catch(error => {
      console.log(error);
    });
  }

  render() {
    return(
      <div className="App">
        <br/>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.map((report) => {
              return(
                <tr>
                  <td>{report.id}</td>
                  <td>{report.report_date}</td>
                  <td><Link to={`/report/manage/${report.id}`}>Manage</Link></td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default withRouter(Report);