import React from 'react';
import axios from 'axios';
import { withRouter } from './withRouter.js';
import {baseURL} from '../config.js';

class EditService extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        authState: false,
        token: "",
        id: this.props.id,
        name: "",
        sectors: [],
        sectorId: 0
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.editService = this.editService.bind(this);
  }

  async componentDidMount() {
    if(localStorage.getItem("token")) {
      var sessionToken = localStorage.getItem("token");
      this.setState({token: sessionToken});
    }

    var authState = await this.props.checkAuthState(sessionToken).then((result) => {
      this.setState({authState: true});
      this.getData(sessionToken);
      this.getSectors(sessionToken);
    }).catch((error) => {
      this.setState({authState: false});
      this.props.navigate('/login');
    });
  }

  getData(token) {
    axios.get(baseURL + '/services', {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }, {}).then(response => {
      if(response.data) {
        var service = response.data.filter(service => {
            return service.id === parseInt(this.state.id)
        });
        this.setState({name: service[0].name});
        this.setState({sectorId: service[0].sector_id});
      } else {
        this.setState({error: "Error getting data"})
      }
    }).catch(error => {
      console.log(error);
    });
  }

  getSectors(token) {
    axios.get(baseURL + '/sectors', {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }, {}).then(response => {
      if(response.data) {
        this.setState({sectors: response.data})
      } else {
        this.setState({error: "Error getting data"})
      }
    }).catch(error => {
      console.log(error);
    });
  }

  handleChange(event) {
    this.setState({name: event.target.value});
  }

  handleSelect(event) {
    this.setState({sectorId: event.target.value});
    console.log(this.state.sectorId);
  }

 editService(event) {
    event.preventDefault();
    axios.put(baseURL + '/services/' + this.state.id, { 
      id: this.state.id, 
      sectorId: this.state.sectorId, 
      name: this.state.name 
    }, {
      headers: {
        'Authorization': 'Bearer ' + this.state.token
      }
    }).then(response => {
      if(response.data) {
        this.props.navigate('/services');
      } else {
        this.setState({error: "Error getting data"})
      }
    }).catch(error => {
      console.log(error);
    });
  }

  render() {
    return(
        <form onSubmit={this.editService}>
            {this.state.sectorId}
        <label>
          Name:
          <input type="text" value={this.state.name} onChange={this.handleChange} />
        </label>
        <select value={this.state.sectorId} onChange={this.handleSelect}>
            {this.state.sectors.map((sector) => {
                return(
                    <option key={"'" + sector.id + "'"} value={sector.id}>{sector.name}</option>
                );
            })}
        </select>
        <input type="submit" value="Save" />
      </form>
    );
  }
}

export default withRouter(EditService);