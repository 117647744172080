import React from 'react';
import axios from 'axios';
import { withRouter } from './withRouter.js';
import {baseURL} from '../config.js';

class EditFAQ extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        id: this.props.id,
        token: "",
        question: "",
        answer: "",
        order: 0
    };

    this.handleChangeQuestion = this.handleChangeQuestion.bind(this);
    this.handleChangeAnswer = this.handleChangeAnswer.bind(this);
    this.handleChangeOrder = this.handleChangeOrder.bind(this);

    this.editFAQ = this.editFAQ.bind(this);
  }

  async componentDidMount() {
    if(localStorage.getItem("token")) {
      var sessionToken = localStorage.getItem("token");
      this.setState({token: sessionToken});
    }

    var authState = await this.props.checkAuthState(sessionToken).then((result) => {
      this.setState({authState: true});
      this.getData(sessionToken);
    }).catch((error) => {
      this.setState({authState: false});
      this.props.navigate('/login');
    });
  }

  getData(sessionToken) {
    axios.get(baseURL + '/faq/' + this.state.id, {
      headers: {
        'Authorization': 'Bearer ' + sessionToken
      }
    }, {}).then(response => {
      if(response.data) {
        var faq = response.data[0];
        this.setState({question: faq.question});
        this.setState({answer: faq.answer});
        this.setState({order: parseInt(faq.position)});
      } else {
        this.setState({error: "Error getting data"})
      }
    }).catch(error => {
      console.log(error);
    });
  }

  handleChangeQuestion(event) {
    this.setState({question: event.target.value});
  }

  handleChangeAnswer(event) {
    this.setState({answer: event.target.value});
  }

  handleChangeOrder(event) {
    this.setState({order: parseInt(event.target.value)});
  }

  editFAQ(event) {
    event.preventDefault();
    axios.put(baseURL + '/faq/' + this.state.id, { 
      question: this.state.question,
      answer: this.state.answer,
      order: this.state.order 
    }, {
      headers: {
        'Authorization': 'Bearer ' + this.state.token
      }
    }).then(response => {
      if(response.data) {
        this.props.navigate('/faq');
      } else {
        this.setState({error: "Error getting data"})
      }
    }).catch(error => {
      console.log(error);
    });
  }

  render() {
    return(
        <form onSubmit={this.editFAQ}>
        <label>
          Question:
          <input type="text" value={this.state.question} onChange={this.handleChangeQuestion} />
        </label>
        <textarea style={{width: 100 + "%", height:10 + "rem"}} value={this.state.answer} onChange={this.handleChangeAnswer}></textarea>
        <label>
          Order:
          <input type="number" value={this.state.order} onChange={this.handleChangeOrder} min="1" max="100" />
        </label>
        <input type="submit" value="Save" />
      </form>
    );
  }
}

export default withRouter(EditFAQ);