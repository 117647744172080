import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {baseURL} from '../config.js';
import { withRouter } from './withRouter.js';

class Logout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        authState: false,
        token: "",
        data: [],
        sectors: [],
        error: ""
    }
  }

  async componentDidMount() {
    if(localStorage.getItem("token")) {
      var sessionToken = localStorage.getItem("token");
      this.setState({token: sessionToken});
    }
    
    this.getData(sessionToken);
  }

  getData(token) {
    axios.post(baseURL + '/logout', {
        token: token
    }, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then(response => {
      if(response.status === 200) {
        this.props.navigate('/');
      } else {
        this.setState({error: "Error getting data"})
      }
    }).catch(error => {
      console.log(error);
    });
  }

  render() {
    return(
      <div className="App">
      </div>
    );
  }
}

export default withRouter(Logout);